const leaflets = {
    "ayoka": {
        "config": {
            "primary-color": "#264280",
            "secondary-color": "#761662",
            "tertiary-color": "#ffdd00",
            "hover-color": "rgba(220, 35, 17, 0.2)",
            "clientIcon": require("assets/images/ayoka_clientLogo.png"),
            "serverPath": "ayoka.volantinointerattivo.net/",
            "pdfPath": "/media/interactive_flyer_105/pdf/volantino.pdf",
            "shareFlyerTitle": "Dai uno sguardo al nuovo volantino di Ayoka!",
            "shareFlyerURL": "https://ayoka.volantinointerattivo.net/leaflet",
            "shareProductSentence": "Guarda questa fantastica offerta di Ayoka!",
            "ogTitleMeta": "Volantino Interattivo Ayoka",
            "ogDescriptionMeta": "Dai uno sguardo al nostro nuovo volantino.",
            "ogImageMeta": "https://ayoka.volantinointerattivo.net/media/interactive_flyer_105/images/share_facebook.png",
            "filterBrand": 0,
            "filterUnderprice": 0,
            "brandImage": "",
            "underPriceImage": "",
            "ga_active": true,
            "ga_tracking_id": "UA-152069090-11",
            "release_id": "1",
        },
        "leaflet": {
            "name": "Ayoka",
            "code": "56854758",
            "id_campaign": "1234",
        "index": {
            "image_file": "/media/interactive_flyer_105/pages/indice_ayoka.png",
            "links": [
                {
                    "page": 2,
                    "blueprint": {
                        "top": 18.73641552513074,
                        "left": 6.946227404056703,
                        "width": 93.05377259594329,
                        "height": 4.664887346449716
                    }
                },
                {
                    "page": 4,
                    "blueprint": {
                        "top": 25.00568582317802,
                        "left": 6.946227404056708,
                        "width": 93.05377259594329,
                        "height": 4.664887346449716
                    }
                },
                {
                    "page": 6,
                    "blueprint": {
                        "top": 31.274956121225294,
                        "left": 6.946227404056708,
                        "width": 93.05377259594329,
                        "height": 4.664887346449716
                    }
                },
                {
                    "page": 6,
                    "blueprint": {
                        "top": 38.058101033866606,
                        "left": 6.946227404056708,
                        "width": 93.05377259594329,
                        "height": 4.664887346449716
                    }
                },
                {
                    "page": 8,
                    "blueprint": {
                        "top": 45.046795792345534,
                        "left": 6.946227404056708,
                        "width": 93.05377259594329,
                        "height": 4.664887346449716
                    }
                }
            ]
        },
        "categories": [
            {
                "category_name": "Cane",
                "subcategories": [
                    "Accessori",
                    "Cibo",
                    "Igiene e Cura"
                ]
            },
            {
                "category_name": "Gatto",
                "subcategories": [
                    "Accessori",
                    "Cibo",
                    "Igiene e Cura"
                ]
            },
            {
                "category_name": "Pesci",
                "subcategories": [
                    "Acquari",
                    "Cibo",
                    "Igiene e Cura"
                ]
            },
            {
                "category_name": "Rettili",
                "subcategories": [
                    "Habitat",
                ]
            },
            {
                "category_name": "Roditori",
                "subcategories": [
                    "Cibo",
                    "Gabbie",
                    "Igiene e Cura"
                ]
            },
            {
                "category_name": "Uccelli",
                "subcategories": [
                    "Cibo",
                    "Gabbie",
                ]
            },
        ],
        "pages": [
            {
                "number": 1,
                "image_file": "/media/interactive_flyer_105/pages/page_1_A8kbdYJ.jpg",
                "products": [
                    {
                        "item_id": "1",
                        "field1": "ASKOLL",
                        "field2": "Aqua 80 led ",
                        "field3": "acquario nero",
                        "field4": "lt.71 cm80x30x31,5h",
                        "description": "ASKOLL Aqua 80 led  acquario nero lt.71 cm80x30x31,5h",
                        "grammage": null,
                        "price": "110.00",
                        "price_label": "€ 110,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Pesci",
                        "subcategory": "Acquari",
                        "blueprint": {
                            "top": 63.78095435933431,
                            "left": 1.5987495717711546,
                            "width": 55.28931140801645,
                            "height": 34.985746565639985
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/acquario1_eRRu48p.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_Mk5fAGZ_JkiVjJ6.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            },
                            {
                                "type": "specs",
                                "title": "Specifiche Tecniche",
                                "data": {
                                    "specifiche": "Modello: AQUA LED 80, Dimensione cm: 80 x 30 x h 31.5, Capacità lt: 71, Filtrazione: Filtro biologico CFBIO 150, Portata max pompa l/h: 260, Illuminazione: 1 LED lightirg system 18w 7000k, Potenza del riscaldatore w: 100, Colore: Nero",
                                    "img": [
                                        "/media/interactive_flyer_105/images/acquario1.jpg"
                                    ]
                                }
                            },
                            {
                                "type": "world",
                                "title": "LINK - Sito web",
                                "data": "https://www.askollaquarium.com/it-it/acquari-e-accessori/acquari-d-acqua-dolce-supporti/aqua-led-aqua-led-plus"
                            }
                        ]
                    }
                ]
            },
            {
                "number": 2,
                "image_file": "/media/interactive_flyer_105/pages/page_2_aWo7BnQ.jpg",
                "products": [
                    {
                        "item_id": "2",
                        "field1": "HAPPY DOG Crocchette",
                        "field2": "Vari tipi",
                        "field3": "kg10",
                        "field4": "",
                        "description": "HAPPY DOG Crocchette Vari tipi kg10",
                        "grammage": null,
                        "price": "34.90",
                        "price_label": "€ 34,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Baby Original",
                            "Junior Original",
                            "Baby Agnello&Riso",
                            "Junior Agnello&Riso"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Cane",
                        "subcategory": "Cibo",
                        "blueprint": {
                            "top": 10.749094133229486,
                            "left": 0.0,
                            "width": 33.63352175402536,
                            "height": 21.625006586194974
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/happy_SRGn3xp.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/happy1_ozha5Mo.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_l2jOnml_KOrlwwk.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "3",
                        "field1": "HILL'S Crocchette",
                        "field2": "Science Plan",
                        "field3": "kg 12",
                        "field4": "",
                        "description": "HILL'S Crocchette Science Plan kg 12",
                        "grammage": null,
                        "price": "39.90",
                        "price_label": "€ 39,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Puppy Medium Pollo",
                            "Puppy Agnello&Riso",
                            "Adult Fitness Large Agnello&riso",
                            "Adult Fitness Tonno&Riso",
                            "Adult Light Pollo",
                            "Adult Light Large Pollo"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Cane",
                        "subcategory": "Cibo",
                        "blueprint": {
                            "top": 8.590820751934523,
                            "left": 33.719081877355265,
                            "width": 32.61613566289826,
                            "height": 23.78327996748994
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/4.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/5.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_2YNQb42_NxisbC1.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            },
                            {   
                            "type": "play",
                            "title": "VIDEO",
                            "data": "/media/interactive_flyer_105/video/hills1.mp4"
                            },
                        ]
                    },
                    {
                        "item_id": "4",
                        "field1": "N&D ",
                        "field2": "Quinoa crocchette cane",
                        "field3": "Adult",
                        "field4": "KG 7",
                        "description": "N&D  Quinoa crocchette cane Adult KG 7",
                        "grammage": null,
                        "price": "43.50",
                        "price_label": "€ 43,50",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Cane",
                        "subcategory": "Cibo",
                        "blueprint": {
                            "top": 8.076946137340483,
                            "left": 66.36647824597465,
                            "width": 33.63352175402536,
                            "height": 24.194379659165172
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/1.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_Qs72nKj_moooZTc.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "5",
                        "field1": "HILL'S",
                        "field2": "Scienze Plan Crocchette",
                        "field3": "di Pollo / Adult",
                        "field4": "KG 12",
                        "description": "HILL'S Scienze Plan Crocchette di Pollo / Adult KG 12",
                        "grammage": null,
                        "price": "31.90",
                        "price_label": "€ 31,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Large ",
                            "Medium"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Cane",
                        "subcategory": "Cibo",
                        "blueprint": {
                            "top": 32.43460286909794,
                            "left": 0.0,
                            "width": 33.63352175402536,
                            "height": 22.241656123707823
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/6.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/7.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_pTwraBN_5lQG1pW.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "05",
                        "field1": "TRAINER Natural Sensitive",
                        "field2": "Crocchette Adult",
                        "field3": "Medium/Maxi",
                        "field4": "KG 12",
                        "description": "Natural Sensitive Crocchette Adult Medium/Maxi KG 12",
                        "grammage": null,
                        "price": "41.90",
                        "price_label": "€ 41,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Salmone ",
                            "Agnello ",
                            "Anatra ",
                            "Maiale"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Cane",
                        "subcategory": "Cibo",
                        "blueprint": {
                            "top": 32.53737779201674,
                            "left": 33.57374100719424,
                            "width": 32.76147653305927,
                            "height": 22.241656123707823
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/8.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_rWT9gyV_Z04i5Yr.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            },
                            { 
                                "type": "play",
                                "title": "VIDEO",
                                "data": "/media/interactive_flyer_105/video/trainer.mp4"
                            },
                        ]
                    },
                    {
                        "item_id": "7",
                        "field1": "TRAINER Natural Sterilised",
                        "field2": "Croccantini",
                        "field3": "KG 1,5",
                        "field4": "",
                        "description": "TRAINER Natural Sterilised Croccantini KG 1,5",
                        "grammage": null,
                        "price": "11.50",
                        "price_label": "€ 11,50",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Salmone",
                            "Prosciutto",
                            "Carne"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gatto",
                        "subcategory": "Cibo",
                        "blueprint": {
                            "top": 32.53737779201674,
                            "left": 66.36647824597465,
                            "width": 33.63352175402536,
                            "height": 22.241656123707823
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/9.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/10.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_fz6wgqF_Ozf92fK.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "8",
                        "field1": "HILL'S",
                        "field2": "Science Plan Optimal Care",
                        "field3": "Croccantini Adult",
                        "field4": "KG 2",
                        "description": "HILL'S Science Plan Optimal Care Croccantini Adult KG 2",
                        "grammage": null,
                        "price": "9.90",
                        "price_label": "€ 9,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Tonno",
                            "Pollo",
                            "Agnello"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gatto",
                        "subcategory": "Cibo",
                        "blueprint": {
                            "top": 54.83953606539804,
                            "left": 33.22875984926344,
                            "width": 32.90681740322029,
                            "height": 22.241656123707823
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/11.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/12.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_ssU78a2_u1FHJXd.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "9",
                        "field1": "SERESTO ",
                        "field2": "Collare Antiparassitario",
                        "field3": "per Cani Superiori a 8kg",
                        "field4": "",
                        "description": "SERESTO  Collare Antiparassitario per Cani Superiori a 8kg",
                        "grammage": null,
                        "price": "27.90",
                        "price_label": "€ 27,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Cane",
                        "subcategory": "Igiene e Cura",
                        "blueprint": {
                            "top": 54.83953606539804,
                            "left": 66.22113737581364,
                            "width": 33.778862624186374,
                            "height": 22.241656123707823
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/15.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_YgNX30P.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            },
                            { 
                                "type": "play",
                                "title": "VIDEO",
                                "data": "/media/interactive_flyer_105/video/seresto.mp4"
                            },
                        ]
                    },
                    {
                        "item_id": "10",
                        "field1": "BAYER Advantix Spot On",
                        "field2": "Antiparassitario per Cani",
                        "field3": "Oltre 10kg, fino a 25kg",
                        "field4": "4 pipette x 2,5ml",
                        "description": "BAYER Advantix Spot On Antiparassitario per Cani Oltre 10kg, fino a 25kg 4 pipette x 2,5ml",
                        "grammage": null,
                        "price": "24.90",
                        "price_label": "€ 24,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Cane",
                        "subcategory": "Igiene e Cura",
                        "blueprint": {
                            "top": 77.03891941586053,
                            "left": 33.37410071942446,
                            "width": 32.90681740322029,
                            "height": 22.241656123707823
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/13.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_9swg08U_kmRKnXU.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "11",
                        "field1": "LEOPET",
                        "field2": "Lola Gabbia per Uccelli",
                        "field3": "Bianca + Accessori",
                        "field4": "",
                        "description": "LEOPET Lola Gabbia per Uccelli Bianca + Accessori",
                        "grammage": null,
                        "price": "29.90",
                        "price_label": "€ 29,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Uccelli",
                        "subcategory": "Gabbie",
                        "blueprint": {
                            "top": 77.14169433877933,
                            "left": 66.51181911613567,
                            "width": 33.488180883864345,
                            "height": 22.241656123707823
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/14.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_kjo6F35_pgwHwW1.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }
                        ]
                    }
                ]
            },
            {
                "number": 3,
                "image_file": "/media/interactive_flyer_105/pages/page_3_DlkbZKK.jpg",
                "products": [
                    {
                        "item_id": "12",
                        "field1": "AMTRA",
                        "field2": "Nanotank Acquario",
                        "field3": "lt.30 cm. 30x30x35h",
                        "field4": "",
                        "description": "AMTRA Nanotank Acquario lt.30 cm. 30x30x35h",
                        "grammage": null,
                        "price": "39.90",
                        "price_label": "€ 39,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Pesci",
                        "subcategory": "Acquari",
                        "blueprint": {
                            "top": 8.385270906096906,
                            "left": 0.0,
                            "width": 33.488180883864345,
                            "height": 24.09160473624636
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/19.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_QXhBhc0_zAjGiOg.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "13",
                        "field1": "EXOTERRA",
                        "field2": "Glass Terrarium",
                        "field3": "cm 45x45x45h",
                        "field4": "",
                        "description": "EXOTERRA Glass Terrarium cm 45x45x45h",
                        "grammage": null,
                        "price": "120.00",
                        "price_label": "€ 120,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Rettili",
                        "subcategory": "Habitat",
                        "blueprint": {
                            "top": 8.076946137340483,
                            "left": 33.86442274751627,
                            "width": 32.180113052415216,
                            "height": 24.399929505002785
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/16.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_ROV7Ml5_o1XbL94.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            },
                            {
                                "type": "world",
                                "title": "LINK - Sito web",
                                "data": "http://www.exo-terra.com/it/products/terrariums.php"
                            }
                        ]
                    },
                    {
                        "item_id": "013",
                        "field1": "EXOTERRA",
                        "field2": "Heat Glo Lampada",
                        "field3": "Infreared",
                        "field4": "R20 150W",
                        "description": "EXOTERRA Heat Glo Lampada Infreared R20 150W",
                        "grammage": null,
                        "price": "18.90",
                        "price_label": "€ 18,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Rettili",
                        "subcategory": "Habitat",
                        "blueprint": {
                            "top": 32.53737779201674,
                            "left": 0.0,
                            "width": 33.92420349434738,
                            "height": 22.138881200789015
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/20.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_E2SXil7_G7Ys47R.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "14",
                        "field1": "EXOTERRA",
                        "field2": "Heat Glo Lampada",
                        "field3": "Infrared",
                        "field4": "",
                        "description": "EXOTERRA Heat Glo Lampada Infrared",
                        "grammage": null,
                        "price": "13.90",
                        "price_label": "€ 13,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "R20 50W",
                            "R20 75W"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Rettili",
                        "subcategory": "Habitat",
                        "blueprint": {
                            "top": 32.43460286909794,
                            "left": 34.009763617677294,
                            "width": 32.180113052415216,
                            "height": 44.64658932000793
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/22.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/23.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_rGKszXL_FMB4NGz.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "15",
                        "field1": "EXOTERRA",
                        "field2": "Heat Glo Lampada",
                        "field3": "Infrared",
                        "field4": "R20 100W",
                        "description": "EXOTERRA Heat Glo Lampada Infrared R20 100W",
                        "grammage": null,
                        "price": "15.90",
                        "price_label": "€ 15,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Rettili",
                        "subcategory": "Habitat",
                        "blueprint": {
                            "top": 54.676258992805764,
                            "left": 0.0,
                            "width": 33.649152106885914,
                            "height": 22.30215827338129
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/21.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_FapvJ6W_WLnQ8ql.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "16",
                        "field1": "IMAC",
                        "field2": "Gioco Cane ",
                        "field3": "Cubo Dentale Nylon",
                        "field4": "e TPR con Corda",
                        "description": "IMAC Gioco Cane  Cubo Dentale Nylon e TPR con Corda",
                        "grammage": null,
                        "price": "5.35",
                        "price_label": "€ 5,35",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Cane",
                        "subcategory": "Accessori",
                        "blueprint": {
                            "top": 76.97841726618705,
                            "left": 0.0,
                            "width": 33.649152106885914,
                            "height": 22.30215827338129
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/17.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_9lAFVPh_J2DIvmd.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "17",
                        "field1": "IMAC",
                        "field2": "Gioco Cane Osso Dentale",
                        "field3": "Nylon e TPR con corda",
                        "field4": "",
                        "description": "IMAC Gioco Cane Osso Dentale Nylon e TPR con corda",
                        "grammage": null,
                        "price": "6.35",
                        "price_label": "€ 6,35",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Cane",
                        "subcategory": "Accessori",
                        "blueprint": {
                            "top": 77.08119218910586,
                            "left": 33.86442274751627,
                            "width": 32.341084275436785,
                            "height": 22.30215827338129
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/18.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_3rqd0t7_YE1WmFu.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "18",
                        "field1": "EXOTERRA",
                        "field2": "Glass Terrarium ",
                        "field3": "cm. 30x30x45h",
                        "field4": "",
                        "description": "EXOTERRA Glass Terrarium  cm. 30x30x45h",
                        "grammage": null,
                        "price": "69.90",
                        "price_label": "€ 69,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Rettili",
                        "subcategory": "Habitat",
                        "blueprint": {
                            "top": 38.74614594039054,
                            "left": 66.4961887632751,
                            "width": 33.50381123672491,
                            "height": 60.32887975334018
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/24.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_GvVFH7F_5j4cbvA.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            },
                            {
                                "type": "world",
                                "title": "LINK - Sito web",
                                "data": "http://www.exo-terra.com/it/products/terrariums.php"
                            }
                        ]
                    }
                ]
            },
            {
                "number": 4,
                "image_file": "/media/interactive_flyer_105/pages/page_4_TD3ntF9.jpg",
                "products": [
                    {
                        "item_id": "19",
                        "field1": "ROYAL CANIN",
                        "field2": "Bocconcini",
                        "field3": "GR 140",
                        "field4": "",
                        "description": "ROYAL CANIN Bocconcini GR 140",
                        "grammage": null,
                        "price": "0.89",
                        "price_label": "€ 0,89",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Medium Adult",
                            "Maxi Adult",
                            "Medium Puppy",
                            "Maxi Puppy"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Cane",
                        "subcategory": "Cibo",
                        "blueprint": {
                            "top": 10.688591983556012,
                            "left": 0.0,
                            "width": 33.21312949640288,
                            "height": 16.546762589928058
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/25.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/26.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_GXO7TuZ_SeaNujJ.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "20",
                        "field1": "MORANDO",
                        "field2": "Professional",
                        "field3": "Patè VARI GUSTI",
                        "field4": "GR. 400/405",
                        "description": "MORANDO Professional Patè VARI GUSTI GR. 400/405",
                        "grammage": null,
                        "price": "0.45",
                        "price_label": "€ 0,45",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Cane",
                        "subcategory": "Cibo",
                        "blueprint": {
                            "top": 10.688591983556012,
                            "left": 33.283059266872215,
                            "width": 33.21312949640288,
                            "height": 16.546762589928058
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/27.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/28.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/29.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_fejlbwP_SpTKybx.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            },
                            { 
                                "type": "specs",
                                "title": "MORANDO - ADULT PATE' CON ANATRA",
                                "data": {
                                    "img": [
                                        "/media/interactive_flyer_105/images/morando.jpg"
                                    ],
                                    "specifiche": "Carni e derivati 50% (anatra 5%), Sostanze minerali, Umidità 78%, Proteina grezza 9.5%, Grassi grezzi 5%, Fibre grezze 0.5%, Ceneri grezze 3%",
                            
                                }
                            },
                        ]
                    },
                    {
                        "item_id": "21",
                        "field1": "MONGE",
                        "field2": "Fresh Patè con Bocconcini",
                        "field3": "Vari Gusti",
                        "field4": "gr. 100",
                        "description": "MONGE Fresh Patè con Bocconcini Vari Gusti gr. 100",
                        "grammage": null,
                        "price": "0.59",
                        "price_label": "€ 0,59",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Tacchino",
                            "Pollo&Verdure"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Cane",
                        "subcategory": "Cibo",
                        "blueprint": {
                            "top": 10.688591983556012,
                            "left": 66.78687050359713,
                            "width": 33.21312949640288,
                            "height": 16.546762589928058
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/30.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/31.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_6SdNObD_DBH6n4n.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "22",
                        "field1": "HAPPY DOG",
                        "field2": "NATURCROQ",
                        "field3": "Crocchette Adult",
                        "field4": "Vari Gusti - KG 12",
                        "description": "HAPPY DOG NATURCROQ Crocchette Adult Vari Gusti - KG 12",
                        "grammage": null,
                        "price": "24.90",
                        "price_label": "€ 24,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Cane",
                        "subcategory": "Cibo",
                        "blueprint": {
                            "top": 27.338129496402882,
                            "left": 0.0,
                            "width": 33.1377183967112,
                            "height": 17.677286742034944
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/32.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/33.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/34.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_pNoqn8c_x8LNDjB.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "23",
                        "field1": "ROYAL CANIN",
                        "field2": "Crocchette Adult",
                        "field3": "Maxi/Medium",
                        "field4": "Kg.15",
                        "description": "ROYAL CANIN Crocchette Adult Maxi/Medium Kg.15",
                        "grammage": null,
                        "price": "42.90",
                        "price_label": "€ 42,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Maxi",
                            "Medium"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Cane",
                        "subcategory": "Cibo",
                        "blueprint": {
                            "top": 27.338129496402882,
                            "left": 33.42840013703323,
                            "width": 33.1377183967112,
                            "height": 17.677286742034944
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/35.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/36.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_OWGMIRh_BNus2wM.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "24",
                        "field1": "ROYAL CANIN",
                        "field2": "Crocchette Cane",
                        "field3": "KG 15",
                        "field4": "",
                        "description": "ROYAL CANIN Crocchette Cane KG 15",
                        "grammage": null,
                        "price": "46.90",
                        "price_label": "€ 46,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Maxi Puppy",
                            "Medium Puppy"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Cane",
                        "subcategory": "Cibo",
                        "blueprint": {
                            "top": 27.338129496402882,
                            "left": 66.8622816032888,
                            "width": 33.1377183967112,
                            "height": 17.677286742034944
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/37.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/38.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_H7cbBZM_qJLystt.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            },
                            { 
                                "type": "play",
                                "title": "VIDEO",
                                "data": "/media/interactive_flyer_105/video/royal.mp4"
                            },
                        ]
                    },
                    {
                        "item_id": "25",
                        "field1": "N&D",
                        "field2": "Quinoa Crocchette",
                        "field3": "Vari Gusti",
                        "field4": "KG 2,5",
                        "description": "N&D Quinoa Crocchette Vari Gusti KG 2,5",
                        "grammage": null,
                        "price": "23.90",
                        "price_label": "€ 23,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Cane",
                        "subcategory": "Cibo",
                        "blueprint": {
                            "top": 45.32374100719424,
                            "left": 51.02012675573827,
                            "width": 48.97987324426174,
                            "height": 17.677286742034944
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/1.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_2g1nDgd_IWJvapB.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "26",
                        "field1": "MORANDO",
                        "field2": "Professional",
                        "field3": "Crocchette",
                        "field4": "KG 15",
                        "description": "MORANDO Professional Crocchette KG 15",
                        "grammage": null,
                        "price": "13.90",
                        "price_label": "€ 13,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Manzo",
                            "Pollo",
                            "Mix Verdura"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Cane",
                        "subcategory": "Cibo",
                        "blueprint": {
                            "top": 63.72045220966085,
                            "left": 0.0,
                            "width": 33.1377183967112,
                            "height": 35.14902363823228
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/39.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/40.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/41.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_nuC75Wl_K3702s7.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "27",
                        "field1": "ROYAL CANIN",
                        "field2": "Mini Adult",
                        "field3": "KG 2",
                        "field4": "",
                        "description": "ROYAL CANIN Mini Adult KG 2",
                        "grammage": null,
                        "price": "12.50",
                        "price_label": "€ 12,50",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Cane",
                        "subcategory": "Cibo",
                        "blueprint": {
                            "top": 63.30935251798561,
                            "left": 33.42840013703323,
                            "width": 33.1377183967112,
                            "height": 17.780061664953752
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/42.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_c9Wrh39_ibT2yFP.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "28",
                        "field1": "CROCI",
                        "field2": "Happy Farm",
                        "field3": "Snacks Vari Gusti",
                        "field4": "gr.80",
                        "description": "CROCI Happy Farm Snacks Vari Gusti gr.80",
                        "grammage": null,
                        "price": "2.50",
                        "price_label": "€ 2,50",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Cane",
                        "subcategory": "Cibo",
                        "blueprint": {
                            "top": 63.20657759506681,
                            "left": 66.8622816032888,
                            "width": 33.1377183967112,
                            "height": 17.780061664953752
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/43.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/44.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_WuWtVYv_Ar2zz2Y.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "29",
                        "field1": "PEDIGREE",
                        "field2": "Biscrok Original",
                        "field3": "Biscotti Cane",
                        "field4": "gr.500",
                        "description": "PEDIGREE Biscrok Original Biscotti Cane gr.500",
                        "grammage": null,
                        "price": "1.75",
                        "price_label": "€ 1,75",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Cane",
                        "subcategory": "Cibo",
                        "blueprint": {
                            "top": 81.19218910585818,
                            "left": 33.43388146625557,
                            "width": 33.1377183967112,
                            "height": 17.780061664953752
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/45.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_5UB6BFY_TDPBE9F.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "30",
                        "field1": "PEDIGREE",
                        "field2": "Dentastix Medium",
                        "field3": "pz. 105",
                        "field4": "",
                        "description": "PEDIGREE Dentastix Medium pz. 105",
                        "grammage": null,
                        "price": "22.90",
                        "price_label": "€ 22,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Cane",
                        "subcategory": "Cibo",
                        "blueprint": {
                            "top": 81.19218910585818,
                            "left": 66.71694073312779,
                            "width": 33.1377183967112,
                            "height": 17.780061664953752
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/46.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_dFCQeoI_QBD2Jj2.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }
                        ]
                    }
                ]
            },
            {
                "number": 5,
                "image_file": "/media/interactive_flyer_105/pages/page_5_H9sXQAv.jpg",
                "products": [
                    {
                        "item_id": "31",
                        "field1": "GOURMET",
                        "field2": "Gold ",
                        "field3": "gr. 85x24",
                        "field4": "",
                        "description": "GOURMET Gold  gr. 85x24",
                        "grammage": null,
                        "price": "9.90",
                        "price_label": "€ 9,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Mousse",
                            "Tortini"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gatto",
                        "subcategory": "Cibo",
                        "blueprint": {
                            "top": 7.913669064748201,
                            "left": 0.0,
                            "width": 33.86442274751627,
                            "height": 19.32168550873587
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/49.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/50.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_Ks9MVjz_6cTfVem.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            },
                            { 
                                "type": "play",
                                "title": "VIDEO",
                                "data": "/media/interactive_flyer_105/video/gourmet.mp4"
                            },
                        ]
                    },
                    {
                        "item_id": "32",
                        "field1": "WHISKAS",
                        "field2": "Straccetti in Gelatina Junior",
                        "field3": "Vari Gusti gr. 85x4",
                        "field4": "",
                        "description": "WHISKAS Straccetti in Gelatina Junior Vari Gusti gr. 85x4",
                        "grammage": null,
                        "price": "1.50",
                        "price_label": "€ 1,50",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Selezione Delicata",
                            "Selezione Assortita",
                            "Selezione Pesce",
                            "Selezione Gustosa"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gatto",
                        "subcategory": "Cibo",
                        "blueprint": {
                            "top": 8.016443987667008,
                            "left": 33.86442274751627,
                            "width": 32.12033230558411,
                            "height": 19.32168550873587
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/51.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/52.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_vEIPv3g_33j0GkV.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "33",
                        "field1": "MORANDO ",
                        "field2": "Professional Patè ",
                        "field3": "Vari Gusti",
                        "field4": "gr. 100",
                        "description": "MORANDO  Professional Patè  Vari Gusti gr. 100",
                        "grammage": null,
                        "price": "0.333333",
                        "price_label": "€ 1,00",
                        "equivalence": 1,
                        "quantity_step": 3,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gatto",
                        "subcategory": "Cibo",
                        "blueprint": {
                            "top": 27.440904419321686,
                            "left": 0.0,
                            "width": 33.86442274751627,
                            "height": 17.780061664953752
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/53.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/54.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/55.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_jOYuClZ_byzoDU0.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "34",
                        "field1": "MORANDO",
                        "field2": "Professional Bocconcini",
                        "field3": "Vari Gusti",
                        "field4": "gr. 400",
                        "description": "MORANDO Professional Bocconcini Vari Gusti gr. 400",
                        "grammage": null,
                        "price": "0.39",
                        "price_label": "€ 0,39",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gatto",
                        "subcategory": "Cibo",
                        "blueprint": {
                            "top": 27.338129496402882,
                            "left": 34.009763617677294,
                            "width": 31.97499143542309,
                            "height": 17.780061664953752
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/56.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/57.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_v3VApYU_jcFTmal.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "35",
                        "field1": "LIFE CAT Natural",
                        "field2": "Le ricette bocconcini",
                        "field3": "Vari Gusti",
                        "field4": "gr.150",
                        "description": "LIFE CAT Natural Le ricette bocconcini Vari Gusti gr.150",
                        "grammage": null,
                        "price": "1.59",
                        "price_label": "€ 1,59",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gatto",
                        "subcategory": "Cibo",
                        "blueprint": {
                            "top": 45.32374100719424,
                            "left": 0.0,
                            "width": 33.86442274751627,
                            "height": 17.780061664953752
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/58.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_avnH4HY_26tm0gt.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "36",
                        "field1": "N&D",
                        "field2": "Quinoa Croccantini",
                        "field3": "Adult Vari Gusti",
                        "field4": "Kg 1,5",
                        "description": "N&D Quinoa Croccantini Adult Vari Gusti Kg 1,5",
                        "grammage": null,
                        "price": "19.90",
                        "price_label": "€ 19,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gatto",
                        "subcategory": "Cibo",
                        "blueprint": {
                            "top": 45.32374100719424,
                            "left": 33.86442274751627,
                            "width": 32.12033230558411,
                            "height": 17.780061664953752
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/59.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/60.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_Uew2IeF_uctr6wK.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "37",
                        "field1": "HAPPY CAT",
                        "field2": "Croccantini Sterilised",
                        "field3": "KG 1,4",
                        "field4": "",
                        "description": "HAPPY CAT Croccantini Sterilised KG 1,4",
                        "grammage": null,
                        "price": "8.90",
                        "price_label": "€ 8,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Agnello",
                            "Salmone",
                            "Manzo"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gatto",
                        "subcategory": "Cibo",
                        "blueprint": {
                            "top": 63.20657759506681,
                            "left": 0.0,
                            "width": 33.719081877355265,
                            "height": 17.780061664953752
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/61.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/62.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_jOA8Kdj_Pdt8CUA.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "38",
                        "field1": "ROYAL CANIN",
                        "field2": "Sterilised Croccantini",
                        "field3": "KG 10",
                        "field4": "",
                        "description": "ROYAL CANIN Sterilised Croccantini KG 10",
                        "grammage": null,
                        "price": "49.90",
                        "price_label": "€ 49,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gatto",
                        "subcategory": "Cibo",
                        "blueprint": {
                            "top": 63.103802672148,
                            "left": 34.009763617677294,
                            "width": 32.26567317574512,
                            "height": 17.780061664953752
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/63.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_4I2f0rW_B1qQ4FR.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "39",
                        "field1": "CATISFACTIONS",
                        "field2": "Snack Vari Gusti",
                        "field3": "gr.60",
                        "field4": "",
                        "description": "CATISFACTIONS Snack Vari Gusti gr.60",
                        "grammage": null,
                        "price": "1.00",
                        "price_label": "€ 1.00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gatto",
                        "subcategory": "Cibo",
                        "blueprint": {
                            "top": 81.19218910585818,
                            "left": 0.0,
                            "width": 33.86442274751627,
                            "height": 17.780061664953752
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/64.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/65.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_sAvYVtw_pKBt3m1.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "40",
                        "field1": "WHISKAS",
                        "field2": "Sticks Snack",
                        "field3": "gr 6x3",
                        "field4": "",
                        "description": "WHISKAS Sticks Snack gr 6x3",
                        "grammage": null,
                        "price": "0.75",
                        "price_label": "€ 0,75",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Salmone",
                            "Pollo",
                            "Manzo"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gatto",
                        "subcategory": "Cibo",
                        "blueprint": {
                            "top": 81.08941418293936,
                            "left": 33.86442274751627,
                            "width": 32.411014045906136,
                            "height": 17.780061664953752
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/66.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/67.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_Mvl13ZF_VEBBysP.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "41",
                        "field1": "FRISKIES",
                        "field2": "Croccantini Adult",
                        "field3": "Vari Gusti",
                        "field4": "KG 2",
                        "description": "FRISKIES Croccantini Adult Vari Gusti KG 2",
                        "grammage": null,
                        "price": "3.99",
                        "price_label": "€ 3,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gatto",
                        "subcategory": "Cibo",
                        "blueprint": {
                            "top": 39.46557040082219,
                            "left": 66.42625899280577,
                            "width": 33.57374100719424,
                            "height": 42.44604316546763
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/47.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/48.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_OReHevx_ATWddYg.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            },
                            { 
                                "type": "play",
                                "title": "VIDEO",
                                "data": "/media/interactive_flyer_105/video/friskies.mp4"
                            },
                        ]
                    }
                ]
            },
            {
                "number": 6,
                "image_file": "/media/interactive_flyer_105/pages/page_6_KrKJTwJ.jpg",
                "products": [
                    {
                        "item_id": "42",
                        "field1": "IMAC",
                        "field2": "Carry Sport Trasportino",
                        "field3": "Vari Colori",
                        "field4": "cm 48,5x34x32",
                        "description": "IMAC Carry Sport Trasportino Vari Colori cm 48,5x34x32",
                        "grammage": null,
                        "price": "10.90",
                        "price_label": "€ 10,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Rosa",
                            "Blu",
                            "Beige"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gatto",
                        "subcategory": "Accessori",
                        "blueprint": {
                            "top": 9.455292908530307,
                            "left": 0.0,
                            "width": 33.28305926687222,
                            "height": 32.06577595066803
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/69.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/70.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/71.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_0uAeGq4_OPq2Cs4.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "43",
                        "field1": "IMAC",
                        "field2": "Zuma Toilette",
                        "field3": "Vari Colori",
                        "field4": "cm 40x56x42,5",
                        "description": "IMAC Zuma Toilette Vari Colori cm 40x56x42,5",
                        "grammage": null,
                        "price": "24.90",
                        "price_label": "€ 24,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Nero ",
                            "Blu"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gatto",
                        "subcategory": "Accessori",
                        "blueprint": {
                            "top": 7.708119218910575,
                            "left": 33.283059266872215,
                            "width": 33.28305926687222,
                            "height": 16.855087358684482
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/imac_zuma1_Kmbkzg7.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/imac_zuma2_VUDBOb0.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_Xldbais_MW2GaSt.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "44",
                        "field1": "IMAC",
                        "field2": "Zampa Tiragraffi",
                        "field3": "cm 49x49x101h",
                        "field4": "",
                        "description": "IMAC Zampa Tiragraffi cm 49x49x101h",
                        "grammage": null,
                        "price": "54.90",
                        "price_label": "€ 54,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gatto",
                        "subcategory": "Accessori",
                        "blueprint": {
                            "top": 7.708119218910575,
                            "left": 66.71694073312777,
                            "width": 33.28305926687222,
                            "height": 16.855087358684482
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/imac_zampa_GvR9aIA.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_WIkxrAl_xBdZmHB.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "45",
                        "field1": "STEFANPLAST",
                        "field2": "Sprint 10 Toilette",
                        "field3": "cm 31x43",
                        "field4": "",
                        "description": "STEFANPLAST Sprint 10 Toilette cm 31x43",
                        "grammage": null,
                        "price": "3.50",
                        "price_label": "€ 3,50",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Marrone",
                            "Blu"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gatto",
                        "subcategory": "Accessori",
                        "blueprint": {
                            "top": 24.665981500513865,
                            "left": 33.433881466255556,
                            "width": 33.28305926687222,
                            "height": 16.855087358684482
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/72.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/73.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_QFSvnuT_W4QdaJj.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "46",
                        "field1": "IMAC",
                        "field2": "Gioco Cani Cuccioli",
                        "field3": "in TPR",
                        "field4": "",
                        "description": "IMAC Gioco Cani Cuccioli in TPR",
                        "grammage": null,
                        "price": "4.90",
                        "price_label": "€ 4,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Cane",
                        "subcategory": "Accessori",
                        "blueprint": {
                            "top": 24.665981500513865,
                            "left": 66.71694073312777,
                            "width": 33.28305926687222,
                            "height": 16.855087358684482
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/imac_giococani_wzLYI8d.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_4yPf8hk_TSrmRh9.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "47",
                        "field1": "SEPICAT",
                        "field2": "Lettiera Sepiolite lt.8",
                        "field3": "",
                        "field4": "",
                        "description": "SEPICAT Lettiera Sepiolite lt.8",
                        "grammage": null,
                        "price": "2.00",
                        "price_label": "€ 2,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gatto",
                        "subcategory": "Igiene e Cura",
                        "blueprint": {
                            "top": 47.584789311408,
                            "left": 33.57922233641658,
                            "width": 33.137718396711215,
                            "height": 17.780061664953752
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/79.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_sttoBmC_MJPcfrP.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "48",
                        "field1": "GIMBI",
                        "field2": "Lettiera Base Trucioli",
                        "field3": "kg.1",
                        "field4": "",
                        "description": "GIMBI Lettiera Base Trucioli kg.1",
                        "grammage": null,
                        "price": "1.00",
                        "price_label": "€ 1,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Roditori",
                        "subcategory": "Igiene e Cura",
                        "blueprint": {
                            "top": 47.482014388489205,
                            "left": 66.57159986296676,
                            "width": 33.137718396711215,
                            "height": 17.780061664953752
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/74.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_TAZOoEx_2AlAQzQ.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "49",
                        "field1": "SUPER NAPPY",
                        "field2": "TAPPETINO",
                        "field3": "60X90",
                        "field4": "PZ10",
                        "description": "SUPER NAPPY TAPPETINO 60X90 PZ10",
                        "grammage": null,
                        "price": "2.95",
                        "price_label": "€ 2,95",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Cane",
                        "subcategory": "Igiene e Cura",
                        "blueprint": {
                            "top": 65.46762589928056,
                            "left": 33.57922233641658,
                            "width": 33.137718396711215,
                            "height": 16.64953751284687
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/supernappy_sBbLYKg.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_48sanFr_vy7a7qB.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "50",
                        "field1": "INODORINA",
                        "field2": "Shampoo/Deo Spray",
                        "field3": "ml. 300",
                        "field4": "",
                        "description": "INODORINA Shampoo/Deo Spray ml. 300",
                        "grammage": null,
                        "price": "4.75",
                        "price_label": "€ 4,75",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Shampoo Mousse Talco",
                            "Shampoo Mousse Aloe",
                            "Shampoo Mousse Delicato",
                            "Shampoo Mousse Latte e Vaniglia",
                            "Deo Spray Talco",
                            "Deo Spray Aloe",
                            "Deo Spray Latte e Vaniglia",
                            "Deo Spray Muschio Bianco"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Cane",
                        "subcategory": "Igiene e Cura",
                        "blueprint": {
                            "top": 65.46762589928056,
                            "left": 66.71694073312777,
                            "width": 33.137718396711215,
                            "height": 16.64953751284687
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/inodorina_yJOOtFh.png",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_IN9xqQJ_ebYHVZP.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "51",
                        "field1": "GILL'S",
                        "field2": "Salviette Detergenti",
                        "field3": "Varie Profumazioni",
                        "field4": "pz.40",
                        "description": "GILL'S Salviette Detergenti Varie Profumazioni pz.40",
                        "grammage": null,
                        "price": "2.00",
                        "price_label": "€ 2,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Cane",
                        "subcategory": "Igiene e Cura",
                        "blueprint": {
                            "top": 82.42548818088386,
                            "left": 33.57922233641658,
                            "width": 66.42077766358344,
                            "height": 16.64953751284687
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/gills1_Zp9KwdS.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/gills2_Ftfc8UG.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_Wv8dVCR_thD149W.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "52",
                        "field1": "CROCI",
                        "field2": "Eco Clean Lettiera",
                        "field3": "lt 6",
                        "field4": "",
                        "description": "CROCI Eco Clean Lettiera lt 6",
                        "grammage": null,
                        "price": "4.50",
                        "price_label": "€ 4,50",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gatto",
                        "subcategory": "Igiene e Cura",
                        "blueprint": {
                            "top": 65.67317574511819,
                            "left": 0.0,
                            "width": 33.28305926687222,
                            "height": 34.32682425488181
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/68.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_2yNamIk_DdVt1d7.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            },
                            { 
                                "type": "play",
                                "title": "VIDEO",
                                "data": "/media/interactive_flyer_105/video/croci.mp4"
                            },
                        ]
                    }
                ]
            },
            {
                "number": 7,
                "image_file": "/media/interactive_flyer_105/pages/page_7_jzUMDZy.jpg",
                "products": [
                    {
                        "item_id": "53",
                        "field1": "IMAC ",
                        "field2": "Gabbia Easy per Cavia",
                        "field3": "cm 80x48,5x42h",
                        "field4": "",
                        "description": "IMAC  Gabbia Easy per Cavia cm 80x48,5x42h",
                        "grammage": null,
                        "price": "34.90",
                        "price_label": "€ 34,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Roditori",
                        "subcategory": "Gabbie",
                        "blueprint": {
                            "top": 7.50256937307297,
                            "left": 0.0,
                            "width": 33.28305926687222,
                            "height": 17.1634121274409
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/imac_gabbia_2m4WW74.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_NEgKWbm_e7255n4.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "54",
                        "field1": "GIMBI",
                        "field2": "Fieno per Roditori",
                        "field3": "kg. 1",
                        "field4": "",
                        "description": "GIMBI Fieno per Roditori kg. 1",
                        "grammage": null,
                        "price": "2.00",
                        "price_label": "€ 2,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Roditori",
                        "subcategory": "Cibo",
                        "blueprint": {
                            "top": 7.297019527235355,
                            "left": 33.42840013703323,
                            "width": 33.28305926687222,
                            "height": 17.163412127440903
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/gimbi_fieno_PtbdrNB.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_blgvQht_kVXxRhN.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "55",
                        "field1": "VITAKRAFT",
                        "field2": "Menù Vital Criceti",
                        "field3": "gr. 400",
                        "field4": "",
                        "description": "VITAKRAFT Menù Vital Criceti gr. 400",
                        "grammage": null,
                        "price": "1.50",
                        "price_label": "€ 1,50",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Roditori",
                        "subcategory": "Cibo",
                        "blueprint": {
                            "top": 7.708119218910587,
                            "left": 66.71694073312777,
                            "width": 33.28305926687222,
                            "height": 16.649537512846866
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/vitakraft_ehtYMlc.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_mQiqqig_WYG28Y2.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "56",
                        "field1": "IMAC",
                        "field2": "Gabbia Serena ",
                        "field3": "cm 50x30x50h",
                        "field4": "",
                        "description": "IMAC Gabbia Serena  cm 50x30x50h",
                        "grammage": null,
                        "price": "36.90",
                        "price_label": "€ 36,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Rosso",
                            "Marrone"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Uccelli",
                        "subcategory": "Gabbie",
                        "blueprint": {
                            "top": 24.665981500513873,
                            "left": 0.0,
                            "width": 33.28305926687222,
                            "height": 16.95786228160329
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/gabbia_serena1_NcYcufK.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/gabbia_serena2_ku3Dt9v.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_6IQVzCX_yXlUYeC.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "57",
                        "field1": "MANITOBA",
                        "field2": "Gran Festa Alimento Completo",
                        "field3": "gr.500",
                        "field4": "",
                        "description": "MANITOBA Gran Festa Alimento Completo gr.500",
                        "grammage": null,
                        "price": "1.65",
                        "price_label": "€ 1,65",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Canarini",
                            "Cardellini Mix",
                            "Pappagallini"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Uccelli",
                        "subcategory": "Cibo",
                        "blueprint": {
                            "top": 24.76875642343268,
                            "left": 33.57374100719424,
                            "width": 33.28305926687222,
                            "height": 16.95786228160329
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/81.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/76.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_vSfTdR7_EMrNcey.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "58",
                        "field1": "MANITOBA",
                        "field2": "Miscela",
                        "field3": "Parrocchetti",
                        "field4": "kg.4",
                        "description": "MANITOBA Miscela Parrocchetti kg.4",
                        "grammage": null,
                        "price": "4.50",
                        "price_label": "€ 4,50",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Uccelli",
                        "subcategory": "Cibo",
                        "blueprint": {
                            "top": 24.76875642343268,
                            "left": 66.71694073312777,
                            "width": 33.28305926687222,
                            "height": 16.95786228160329
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/manitoba_miscela_cWaKoAE.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_ZFbXjv1_XxcJiQU.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "59",
                        "field1": "TETRA PLANT",
                        "field2": "Plantamin",
                        "field3": "ml.100",
                        "field4": "",
                        "description": "TETRA PLANT Plantamin ml.100",
                        "grammage": null,
                        "price": "3.50",
                        "price_label": "€ 3,50",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Pesci",
                        "subcategory": "Igiene e Cura",
                        "blueprint": {
                            "top": 47.58478931140802,
                            "left": 0.0,
                            "width": 34.155104487838315,
                            "height": 34.42959917780062
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/tetraplant_Xn8e2E9.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_840avuO_AIc4t5r.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "60",
                        "field1": "SERA",
                        "field2": "Goldy Mangime Completo",
                        "field3": "per Pesci Rossi",
                        "field4": "ml 250",
                        "description": "SERA Goldy Mangime Completo per Pesci Rossi ml 250",
                        "grammage": null,
                        "price": "3.90",
                        "price_label": "€ 3,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Pesci",
                        "subcategory": "Cibo",
                        "blueprint": {
                            "top": 47.89311408016444,
                            "left": 34.300445357999315,
                            "width": 32.847036656389186,
                            "height": 17.471736896197328
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/sera_goldy_Tq3BfRG.jpeg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_QKm8RVg_URix83s.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "61",
                        "field1": "SERA",
                        "field2": "Vipan Mangime Completo",
                        "field3": "per Pesci Tropicali d'Acqua Dolce",
                        "field4": "ml.250",
                        "description": "SERA Vipan Mangime Completo per Pesci Tropicali d'Acqua Dolce ml.250",
                        "grammage": null,
                        "price": "4.75",
                        "price_label": "€ 4,75",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Pesci",
                        "subcategory": "Cibo",
                        "blueprint": {
                            "top": 65.46762589928058,
                            "left": 34.300445357999315,
                            "width": 32.847036656389186,
                            "height": 16.649537512846866
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/77.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_u64pLkV_V5Gx5lU.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "62",
                        "field1": "ASKOLL",
                        "field2": "Co2 Pro Green System",
                        "field3": "Bombola Non Ricaricabile",
                        "field4": "gr. 500",
                        "description": "ASKOLL Co2 Pro Green System Bombola Non Ricaricabile gr. 500",
                        "grammage": null,
                        "price": "19.90",
                        "price_label": "€ 19,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Pesci",
                        "subcategory": "Igiene e Cura",
                        "blueprint": {
                            "top": 82.21993833504625,
                            "left": 0.0,
                            "width": 34.009763617677294,
                            "height": 16.649537512846866
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/78.jpg",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_Mmc5ZIC_T6NLLwD.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "63",
                        "field1": "AMTRA",
                        "field2": "Glax Ring / Axorb Carbone",
                        "field3": "",
                        "field4": "",
                        "description": "AMTRA Glax Ring / Axorb Carbone",
                        "grammage": null,
                        "price": "7.50",
                        "price_label": "€ 7,50",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Glax Ring Anelli in Vetro",
                            "Axorb Carbone Iperattivo"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Pesci",
                        "subcategory": "Igiene e Cura",
                        "blueprint": {
                            "top": 82.32271325796506,
                            "left": 34.300445357999315,
                            "width": 33.28305926687223,
                            "height": 16.649537512846866
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/axorb_d7TNtXb.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/glax_ring_sNi4uWr.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_iDR3FPX_YeTC3sK.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "64",
                        "field1": "TETRA MIN",
                        "field2": "Granules ",
                        "field3": "ml. 250",
                        "field4": "",
                        "description": "TETRA MIN Granules  ml. 250",
                        "grammage": null,
                        "price": "5.90",
                        "price_label": "€ 5,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Pesci",
                        "subcategory": "Cibo",
                        "blueprint": {
                            "top": 62.384378211716346,
                            "left": 67.44364508393285,
                            "width": 32.55635491606716,
                            "height": 37.61562178828366
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_105/products/tetramin_b8tqZGx.png",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_105/products/cropped_image_HMCEJI9_Mf5HtzR.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }
                        ]
                    }
                ]
            },
            {
                "number": 8,
                "image_file": "/media/interactive_flyer_105/pages/page_8_I8pth9l.jpg",
                "products": []
            },
            {
                "number": 9,
                "image_file": "/media/interactive_flyer_105/pages/fine_pagina_ayoka.png",
                "products": []
            }
        ]
    }
    
    }

}

const lefletSwitch = () => {
    return leaflets.ayoka;
}

export const serverResponse = lefletSwitch();